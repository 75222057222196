import {mapState} from 'vuex';

export default {
  data() {
    return {
      // TODO: why is 'groen' missing?
      types: ['Elektra', 'Gas', 'Meetdiensten', 'PM Energiemonitor', 'Zon'],
    };
  },
  computed: mapState({
    organization: state => state.organization,
    currentLocation(state) {
      if (state.currentLocation && state.organization) {
        const location = state.organization.locations.find((obj) => {
          return obj.id === state.currentLocation;
        });

        if (location) {
          return location;
        }
      }

      return state.organization.locations[0] || null;
    },
  }),
  methods: {
    productTypesByLocation(location, year = null) {
      const products = this.productsByLocation(location, year);

      const selected = products.map(p => p.product);
      const productTypes = {};

      this.types.forEach((type) => {
        productTypes[type] = selected.includes(type);
      });

      return productTypes;
    },
    activeTypesByLocation(location, year = null) {
      const types = this.productTypesByLocation(location, year);

      const active = {};

      // We want all the types which are still false (inactive)
      Object.keys(types).forEach((key) => {
        if (!types[key]) {
          active[key] = '1';
        }
      });

      return active;
    },
    productsByLocation(location, year = null) {
      const productsByYear = {};

      year = year || new Date().getFullYear();

      if (!location || !location.product_data) {
        return [];
      }

      location.product_data.forEach((product) => {
        if (!productsByYear[product.jaar]) {
          productsByYear[product.jaar] = [];
        }

        productsByYear[product.jaar].push(product);
      });

      return productsByYear[year] || [];
    },
    getTarifIncludingFee(tarif, fee, decimals = 3) {
      let total = 0;

      if (typeof tarif !== 'undefined') {
        total += parseFloat(tarif.replace(/,/g, '.'));
      }
      if (typeof fee !== 'undefined') {
        total += parseFloat(fee.replace(/,/g, '.'));
      }

      return total.toFixed(decimals).replace('.', ',');
    },
  },
};
