<template>
  <v-navigation-drawer v-model="drawer" app class="py-4">
    <v-layout row wrap justify-center>
      <v-flex xs6>
        <nuxt-link to="/">
          <img src="~/assets/img/logo-pm-energie.png" alt="PM Energie">
        </nuxt-link>
      </v-flex>
    </v-layout>
    <v-list v-if="organization">
      <v-divider class="my-3 divider"/>
      <v-list-group v-model="locationPanel">
        <template #activator>
          <v-list-tile class="list-item">
            <v-list-tile-action>
              <font-awesome-icon :icon="['fal', 'map-marked-alt']" size="lg"/>
              <font-awesome-icon :icon="['fal', 'check']" :color="$vuetify.theme.success"/>
            </v-list-tile-action>
            <v-list-tile-content v-if="currentLocation" class="font-weight-bold">
              <v-list-tile-title>Kies uw locatie</v-list-tile-title>
              <v-list-tile-sub-title class="caption mt-1">
                {{ currentLocation.address.full }}
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
        <v-list-tile
          v-for="(location, index) in organization.locations"
          :key="index"
          @click="setLocation(location.id)"
        >
          <v-list-tile-content>
            <v-list-tile-title class="caption">
              {{ location.address.full }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list-group>
      <v-list-tile v-for="item in items" :key="item.title" v-bind="item.route" exact class="list-item">
        <v-list-tile-action>
          <font-awesome-icon :icon="['fal', item.icon]" size="lg"/>
        </v-list-tile-action>
        <v-list-tile-content class="font-weight-bold">
          <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          <v-list-tile-sub-title v-if="item.locations" class="caption">
            {{ item.title }}
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action v-if="item.external">
          <font-awesome-icon :icon="['fal', 'external-link']" size="xs"/>
        </v-list-tile-action>
      </v-list-tile>
      <v-layout row wrap align-center class="drawer__spacer">
        <v-flex shrink>
          <v-subheader class="caption">
            Informatie
          </v-subheader>
        </v-flex>
        <v-flex>
          <v-divider/>
        </v-flex>
      </v-layout>
      <v-list-tile v-for="item in menuInformation" :key="item.title" v-bind="item.route" class="list-item">
        <v-list-tile-action>
          <font-awesome-icon :icon="['fal', item.icon]" size="lg"/>
        </v-list-tile-action>
        <v-list-tile-content class="font-weight-bold">
          <v-list-tile-title>{{ item.title }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <div v-if="currentUser.can_export">
        <v-layout row wrap align-center class="drawer__spacer">
          <v-flex shrink>
            <v-subheader class="caption">
              Export
            </v-subheader>
          </v-flex>
          <v-flex>
            <v-divider/>
          </v-flex>
        </v-layout>
        <v-list-tile v-bind="{to: {name: 'export'}, nuxt: true}" class="list-item">
          <v-list-tile-action>
            <font-awesome-icon :icon="['fal', 'file-spreadsheet']" size="lg"/>
          </v-list-tile-action>
          <v-list-tile-content class="font-weight-bold">
            <v-list-tile-title>Export</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapState} from 'vuex';
import locationMixin from '~/plugins/mixins/location';

export default {
  mixins: [locationMixin],
  data() {
    return {
      locationPanel: false,
      menuInformation: [
        {
          title: 'Heeft u een vraag?',
          icon: 'question',
          route: {to: {name: 'faq'}, nuxt: true},
        },
        {title: 'Contact', icon: 'phone', route: {to: {name: 'contact'}, nuxt: true}},
      ],
      right: null,
    };
  },
  computed: {
    ...mapState({
      drawerState: state => state.drawer,
      currentUser: state => state.auth.user,
    }),
    items() {
      return [
        {title: 'Dashboard', icon: 'tachometer', route: {to: '/', nuxt: true}},
        {
          title: 'Mijn gegevens',
          icon: 'user-cog',
          route: {to: {name: 'mijn-gegevens'}, nuxt: true},
        },
        {
          title: 'Mijn locaties',
          icon: 'map-marker-alt',
          route: {to: {name: 'locaties'}, nuxt: true},
        },
        {
          title: 'PM Energiemonitor',
          icon: 'chart-bar',
          route: {
            href: 'https://energiemonitor.pmenergie.nl',
            target: '_blank',
            rel: 'noreferrer noopener',
          },
          external: true,
        },
        ...(this.currentLocation && this.currentLocation.id
          ? [{
            title: 'Mijn producten',
            icon: 'plug',
            route: {
              to: {
                name: 'locaties-id',
                params: {id: this.currentLocation.id},
                nuxt: true,
              },
            },
          }]
          : []),
      ];
    },
    drawer: {
      set(drawer) {
        this.$store.commit('SET_DRAWER', drawer);
      },
      get() {
        return this.drawerState;
      },
    },
  },
  watch: {
    organization(organization) {
      if (organization.locations[0]) {
        this.setLocation(organization.locations[0].id);
      }
    },
  },
  methods: {
    setLocation(locationId) {
      this.$store.commit('SET_CURRENT_LOCATION_ID', locationId);
      this.locationPanel = false;

      if (this.$route.name === 'locaties-id') {
        this.$router.push({name: this.$route.name, params: {id: locationId}});
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/style/colors'

.divider
  margin-left 30px
  margin-right 30px

.drawer__spacer
  padding 0 30px 0 14px

  .v-subheader
    padding-right: 6px;
    font-weight: bold;

>>> .v-list__tile
  padding 0 30px
  border-left 4px solid transparent

  &:hover, &:focus, &:active, &:active:focus
    background accent
    border-color primary
    text-decoration none

>>> .v-list__tile--active
  background accent
  border-left 4px solid primary
  color black !important

>>> .v-list__tile__action
  min-width 40px

>>> .v-list__tile__action--stack
  align-items flex-start
  justify-content space-between
  padding-top 5px
  padding-bottom 3px

>>> .v-list__group:before, >>> .v-list__group:after
  content none

>>> .v-list__group__items

  .v-list__tile
    height 30px

  > div:first-child
    padding-top 8px

  > div:last-child
    padding-bottom 8px

>>> .v-list__group__header__append-icon
  transform translate(-45px, -11px) scale(.9)
  width 0
  padding 0
</style>
