import {GetOrganization} from '~/graphql/organization';
import {GetSettings} from '~/graphql/settings';
import {GetPage} from '~/graphql/page';

export const actions = {
  async nuxtServerInit({dispatch, rootState}) {
    await dispatch('storeDispatchFunc');

    if (rootState.auth.user?.organizations?.[0]?.id) {
      await dispatch('getOrganization', rootState.auth.user.organizations[0].id);
    }
  },

  async storeDispatchFunc({commit}) {
    const settings = await this.app.apolloProvider.defaultClient.query({
      query: GetSettings,
    });

    const settingsMap = {};
    settings.data.settings.forEach((setting) => {
      settingsMap[setting.key] = setting.value;
    });

    commit('SET_SETTINGS', settingsMap);
  },

  async getPage({commit}, slug = '') {
    const page = await this.app.apolloProvider.defaultClient.query({
      query: GetPage,
      variables: {
        slug,
      },
    });

    if (!page.data.page) {
      this.app.nuxt.error(404);
    }

    commit('SET_PAGE', page.data.page);
  },

  async getOrganization({commit}, id) {
    let organization = await this.app.apolloProvider.defaultClient.query({
      query: GetOrganization,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });

    // map data to new api
    organization = mapOrganization(organization.data.organisatie);

    commit('SET_ORGANIZATION', organization);
    commit('SET_CURRENT_LOCATION_ID', organization.locations?.[0]?.id ?? -1);
  },
};

export const state = () => ({
  release: process.env.RELEASE,
  settings: {},
  page: {},
  currentLocation: null,
  organization: {locations: []},
  drawer: null,
});

export const mutations = {
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_CURRENT_LOCATION_ID(state, payload) {
    state.currentLocation = payload ? parseInt(payload, 10) : null;
  },
  SET_ORGANIZATION(state, payload) {
    state.organization = payload;
  },
  SET_DRAWER(state, payload) {
    if (payload !== state.drawer) {
      state.drawer = payload;
    }
  },
};

function mapOrganization(org) {
  function mapContact(contact) {
    return {
      id: contact.id,
      name: `${contact.voornaam ?? ''} ${contact.tussenvoegsels ?? ''} ${contact.title}`.trim(),
      first_name: contact.voornaam ?? contact.voorletters ?? null,
      prefix: contact.tussenvoegsels,
      last_name: contact.title,
      email: contact.e_mailadres,
      phone: contact.telefoon,
      mobile: contact.mobiel,
    };
  }

  function mapAddress(address) {
    return {
      id: address.id,
      street: address.title,
      nr: address.nummer ?? 0,
      nr_suffix: address.toevoeging,
      zip: address.postcode ?? '0000 AA',
      city: address.plaats ?? 'Onbekende plaats',
      full: `${address.title} ${(address.nummer ?? '') + (address.toevoeging ? '-' + address.toevoeging.trim() : '')} ${address.postcode ?? ''} ${address.plaats ?? ''}`.trim(),
    };
  }

  function mapContract(contract) {
    return {
      id: contract.id,
      title: contract.title,
      jaar: contract.jaar,
      copied_from: null, // TODO: missing field
      vastrecht: null, // TODO: missing field
      // TODO: contracthoeveelheid == levering?
      contracthoeveelheid_piek: contract.levering_piek,
      contracthoeveelheid_dal: contract.levering_dal,
      contracthoeveelheid_totaal: contract.levering_totaal,
      teruglevering_piek: contract.teruglevering_piek,
      teruglevering_dal: contract.teruglevering_dal,
      // elektra
      teruglevering_totaal: contract.teruglevering_totaal,
      fee_g: String(contract.fee_g),
      fee_g_vr: String(contract.fee_gv),
      // gas
      fee_e_vr: String(contract.fee_ev),
      fee_e_tot_100: String(contract.fee_e_100),
      fee_vanaf_100: String(contract.fee_e_gt_100),
      // groen
      type_groen: contract.type_groen,
      // meetdiensten
      categorie_aansluitwaarde: null, // TODO: missing field
      // merge raamcontract
      raamcontract: contract.raamcontract?.title,
      product: contract.raamcontract?.product,
      opslag_piek: contract.raamcontract?.opslag_piek,
      opslag_dal: contract.raamcontract?.opslag_dal,
      opslag_totaal: contract.raamcontract?.opslag_totaal,
      tarief_piek: String(contract.raamcontract?.tarief_piek),
      tarief_dal: String(contract.raamcontract?.tarief_dal),
      tarief_totaal: String(contract.raamcontract?.tarief_totaal),
      vaste_kosten: String(contract.raamcontract?.vaste_kosten),
      klikpercentage: contract.raamcontract?.klik_percentage,
      contractopmerkingen_klantportaal: contract.raamcontract?.opmerking,
    };
  }

  function mapAansluiting(aansluiting) {
    // TODO: handle multiple invoice addresses
    const invAddr = aansluiting.factuuradressen?.[0];
    const allContracts = [];

    // eslint-disable-next-line no-unused-vars
    for (const index in aansluiting.contracten) {
      const contract = aansluiting.contracten[index];

      // set PM Energiemonitor to true when Meetdiensten are available
      if (contract.raamcontract?.product === 'Meetdiensten') {
        allContracts.push({
          jaar: contract.jaar,
          product: 'PM Energiemonitor',
          activeBecauseOfMeetdiensten: true,
        });
      }

      allContracts.push(mapContract(contract));
    }

    return {
      id: aansluiting.id,
      title: aansluiting.title,
      address: mapAddress(aansluiting.leveradres ?? dummy),
      invoice_address: mapAddress(invAddr ?? dummy),
      contact: mapContact(contact ?? dummy),
      connection_type: aansluiting.type_aansluiting,
      product_data: allContracts,
      invoice_name: org.title,
      invoice_email: digitalInvoiceEmail?.title,
    };
  }

  const dummy = {id: -1, title: 'Geen'};
  const contact = org.standaard_contactpersoon ?? org.contactpersonen?.[0] ?? dummy;
  const aansluitingen = org.aansluitingen ?? [dummy];
  const digitalInvoiceEmail = org.factuuradressen?.find((addr) => {
    return addr.digitaal_factuuradres === true;
  });

  return {
    id: org.id,
    title: org.title,
    phone: org.telefoon,
    email: org.e_mailadres,
    digital_invoice_email: digitalInvoiceEmail?.title,
    contact: mapContact(contact),
    address: mapAddress(org.bezoekadres ?? dummy),
    locations: aansluitingen.map(aansluiting => mapAansluiting(aansluiting)),
  };
}
