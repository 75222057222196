<template>
  <v-snackbar v-model="snackbarActive" v-bind="snackbar.props" top multi-line>
    {{ snackbar.message }}
  </v-snackbar>
</template>

<script>
import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState({
      snackbar: state => state.validation.snackbar,
    }),
    snackbarActive: {
      set(snackbarActive) {
        this.$store.commit('validation/SET_SNACKBAR_ACTIVE', snackbarActive);
      },
      get() {
        return this.snackbar.active;
      },
    },
  },
};
</script>
