import Vue from 'vue';
import Vuetify from 'vuetify';
import nl from 'vuetify/es5/locale/nl';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {
  faBarcodeRead,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faEuroSign,
  faSearch,
  faPlus,
  faThumbsUp,
  faAngleUp,
  faPhone,
  faMapMarkerAlt,
  faEnvelope,
  faExternalLink,
  faMapMarkedAlt,
  faCheck,
  faChartBar,
  faUserCog,
  faBars,
  faQuestion,
  faUser,
  faSignOut,
  faTimes,
  faPlug,
  faTachometer,
  faCog,
  faLock,
  faSquare,
  faFileSpreadsheet,
  faCheckSquare,
  faCircle,
  faCheckCircle,
  faMinusSquare,
} from '@fortawesome/pro-light-svg-icons';

config.autoAddCss = false;

library.add(
  faPhone,
  faCheckCircle,
  faBarcodeRead,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faSearch,
  faPlus,
  faThumbsUp,
  faAngleUp,
  faEuroSign,
  faEnvelope,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faExternalLink,
  faCheck,
  faUserCog,
  faTachometer,
  faChartBar,
  faBars,
  faQuestion,
  faUser,
  faSignOut,
  faCog,
  faLock,
  faTimes,
  faPlug,
  faSquare,
  faFileSpreadsheet,
  faCheckSquare,
  faCircle,
  faCheckCircle,
  faMinusSquare,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.use(Vuetify, {
  lang: {
    locales: {nl},
    current: 'nl',
  },
  icons: {
    sort: {
      component: FontAwesomeIcon,
      props: {
        icon: ['far', 'angle-up'],
      },
    },
    prev: {
      component: FontAwesomeIcon,
      props: {
        icon: ['far', 'angle-left'],
      },
    },
    next: {
      component: FontAwesomeIcon,
      props: {
        icon: ['far', 'angle-right'],
      },
    },
    menu: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fal', 'bars'],
      },
    },
    dropdown: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fal', 'angle-down'],
      },
    },
    subgroup: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fal', 'angle-down'],
      },
    },
    expand: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fal', 'angle-down'],
        size: 'xs',
      },
    },
    user: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fal', 'user'],
      },
    },
    checkboxOn: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fal', 'check-square'],
      },
    },
    checkboxOff: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fal', 'square'],
      },
    },
    checkboxIndeterminate: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fal', 'minus-square'],
      },
    },
    radioOff: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fal', 'circle'],
      },
    },
    radioOn: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fal', 'check-circle'],
      },
    },
    plus: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fal', 'plus'],
        size: 'xs',
      },
    },
  },
  theme: {
    black: '#000000',
    primary: '#64BF26',
    secondary: '#002F5C',
    accent: '#F0F0F0',
    success: '#64bf26',
    info: '#008999',
    warning: '#EFA105',
    error: '#FE4A49',
  },
});
