<template>
  <v-toolbar app class="elevation-0" color="#FDFDFD">
    <v-toolbar-side-icon @click.stop="toggleDrawer"/>
    <v-spacer/>
    <v-combobox
      v-if="user"
      v-model="currentOrganization"
      :items="user.organizations"
      :menu-props="{maxWidth: 300, right: true}"
      placeholder="Kies een organisatie"
      hide-details
      item-value="id"
      item-text="title"
      flat
    />
    <v-menu offset-y open-on-hover>
      <template #activator="{on}">
        <v-btn flat icon v-on="on">
          <font-awesome-icon :icon="['fal', 'user']" :color="$vuetify.theme.primary" size="2x"/>
        </v-btn>
      </template>
      <v-list dense class="elevation-0">
        <v-list-tile :to="{name: 'wachtwoord-wijzigen'}" nuxt>
          <v-list-tile-action>
            <font-awesome-icon :icon="['fal', 'lock']"/>
          </v-list-tile-action>
          <v-list-tile-title>Wachtwoord wijzigen</v-list-tile-title>
        </v-list-tile>
        <v-list-tile @click="logout">
          <v-list-tile-action>
            <font-awesome-icon :icon="['fal', 'sign-out']"/>
          </v-list-tile-action>
          <v-list-tile-title>Uitloggen</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>

<script>
import {mapState} from 'vuex';
import locationMixin from '~/plugins/mixins/location';

export default {
  mixins: [locationMixin],
  data() {
    return {
      currentOrganization: null,
    };
  },
  computed: {
    ...mapState({
      drawer: state => state.drawer,
    }),
  },
  watch: {
    currentOrganization(organization) {
      if (!organization || !organization.id) {
        return;
      }

      this.setOrganization(organization.id);
    },
  },
  mounted() {
    if (this.organization && this.organization.id) {
      this.currentOrganization = {
        id: this.organization.id,
        title: this.organization.title,
      };
    }
  },
  methods: {
    async logout() {
      await this.$auth.logout();
    },
    toggleDrawer() {
      this.$store.commit('SET_DRAWER', !this.drawer);
    },
    async setOrganization(id) {
      await this.$store.dispatch('getOrganization', id);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/style/colors'

>>> .v-toolbar__content
  border-bottom 2px solid primary

>>> .theme--light.v-text-field > .v-input__control > .v-input__slot
  &:before, &:after
    content none !important

>>> .v-select
  padding-top 0

  input
    text-align right

@media only screen and (max-width: 599px)
  >>> .v-btn.text-uppercase
    margin-right 0
    padding-right 0
</style>
