const middleware = {}

middleware['canExport'] = require('../middleware/canExport.js')
middleware['canExport'] = middleware['canExport'].default || middleware['canExport']

middleware['clearValidationErrors'] = require('../middleware/clearValidationErrors.js')
middleware['clearValidationErrors'] = middleware['clearValidationErrors'].default || middleware['clearValidationErrors']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

export default middleware
