<template>
  <v-app>
    <navigation-drawer/>
    <toolbar/>
    <v-content>
      <v-container fluid grid-list-xl class="container">
        <nuxt/>
      </v-container>
    </v-content>
    <snackbar/>
  </v-app>
</template>

<script>
import Toolbar from '~/layouts/partials/Toolbar';
import NavigationDrawer from '~/components/NavigationDrawer';
import Snackbar from '~/components/Snackbar';

export default {
  components: {
    NavigationDrawer,
    Toolbar,
    Snackbar,
  },
};
</script>

<style lang="stylus" scoped>
.container
  min-height 100%

@media only screen and (min-width: 600px)
  .container.grid-list-xl
    padding 30px

@media only screen and (min-width: 960px)
  .container.grid-list-xl
    padding 40px

    >>> .card-wrap
      padding 18px
</style>
