<template>
  <v-app>
    <v-content>
      <v-container fluid grid-list-xl>
        <nuxt/>
      </v-container>
    </v-content>
    <snackbar/>
  </v-app>
</template>

<script>
import Snackbar from '~/components/Snackbar';

export default {
  components: {
    Snackbar,
  },
};
</script>
