<template>
  <div class="pa-4">
    <template v-if="error.statusCode === 410 || error.statusCode === 404">
      <h1 class="headline font-weight-bold mb-4">{{ page.title }}</h1>
      <!--eslint-disable-next-line-->
      <div v-if="page.template" v-html="page.template.tekst"/>
    </template>
    <h1 v-else>
      Er is een fout opgetreden
    </h1>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  auth: false,
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  head() {
    return {
      title: this.page.meta ? this.page.meta.title : this.page.title,
      meta: [
        this.page.meta && this.page.meta.description
          ? {
            hid: 'description',
            name: 'description',
            content: this.page.meta.description,
          }
          : {},
      ],
    };
  },
  computed: mapState(['page']),
  async created() {
    await this.$store.dispatch('getPage', '404');
    console.log(this.page);
  },
};
</script>
