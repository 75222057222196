<template>
  <div>
    <v-alert :value="success" type="success">
      Het formulier is succesvol verstuurd.
    </v-alert>
    <form method="post" class="mb-5" @submit.prevent="submit">
      <v-layout>
        <v-flex>
          <div v-for="field in template.fields" :key="field.position">
            <v-text-field
              v-if="field.type === 'text'"
              :id="field.name + '_' + id"
              v-model="form[field.name]"
              :error-messages="errors[field.name]"
              :label="field.label"
            />
            <v-text-field
              v-if="field.type === 'email'"
              :id="field.name + '_' + id"
              v-model="form[field.name]"
              :error-messages="errors[field.name]"
              :label="field.label"
              type="email"
            />
            <v-text-field
              v-if="field.type === 'number'"
              :id="field.name + '_' + id"
              v-model="form[field.name]"
              :error-messages="errors[field.name]"
              :label="field.label"
              type="number"
            />
            <v-select
              v-if="field.type === 'select'"
              :id="field.name + '_' + id"
              :items="field.options"
              :error-messages="errors[field.name]"
              :label="field.label"
            />
            <v-textarea
              v-if="field.type === 'textarea'"
              :id="field.name + '_' + id"
              v-model="form[field.name]"
              :error-messages="errors[field.name]"
              :label="field.label"
            />
            <template v-if="field.type === 'checkbox'">
              <p>{{ field.label }}</p>
              <v-checkbox
                v-for="(option, index) in field.options"
                :key="index"
                v-model="form[field.name]"
                :label="option"
                :value="option"
                :hide-details="index < field.options.length - 1"
                :error="errors[field.name] && errors[field.name].length > 0"
              />
            </template>
          </div>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-spacer />
        <v-btn v-if="template.fields" type="submit" depressed color="primary" class="mx-0 px-4 font-weight-bold">
          Versturen
        </v-btn>
      </v-layout>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    html: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      success: false,
      template: {},
      form: {},
    };
  },
  async mounted() {
    const fields = await this.$axios.get('/forms/' + this.id);

    this.template = fields.data.data;
  },
  methods: {
    async submit() {
      await this.$axios.post('/forms/' + this.id, this.form);

      this.success = true;
      this.form = {};
    },
  },
};
</script>
