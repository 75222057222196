import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5d93fd27 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _54ece806 = () => interopDefault(import('../pages/export.vue' /* webpackChunkName: "pages/export" */))
const _6deeeec6 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _4cf43c7a = () => interopDefault(import('../pages/inloggen.vue' /* webpackChunkName: "pages/inloggen" */))
const _095baf50 = () => interopDefault(import('../pages/locaties/index.vue' /* webpackChunkName: "pages/locaties/index" */))
const _0e1f7784 = () => interopDefault(import('../pages/mijn-gegevens.vue' /* webpackChunkName: "pages/mijn-gegevens" */))
const _00d3176f = () => interopDefault(import('../pages/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/index" */))
const _3fb18799 = () => interopDefault(import('../pages/wachtwoord-wijzigen.vue' /* webpackChunkName: "pages/wachtwoord-wijzigen" */))
const _3771a7aa = () => interopDefault(import('../pages/locaties/nieuw.vue' /* webpackChunkName: "pages/locaties/nieuw" */))
const _5a6b2899 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0fa5aaad = () => interopDefault(import('../pages/wachtwoord-vergeten/reset/_token.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/reset/_token" */))
const _012ca23b = () => interopDefault(import('../pages/locaties/_id/index.vue' /* webpackChunkName: "pages/locaties/_id/index" */))
const _4241ba11 = () => interopDefault(import('../pages/wachtwoord-instellen/_token.vue' /* webpackChunkName: "pages/wachtwoord-instellen/_token" */))
const _8b4833d2 = () => interopDefault(import('../pages/locaties/_id/gegevens.vue' /* webpackChunkName: "pages/locaties/_id/gegevens" */))
const _b984dce0 = () => interopDefault(import('../pages/locaties/_id/wijzigen.vue' /* webpackChunkName: "pages/locaties/_id/wijzigen" */))
const _3cb5f91e = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _5d93fd27,
    pathToRegexpOptions: {"strict":true},
    name: "contact"
  }, {
    path: "/export",
    component: _54ece806,
    pathToRegexpOptions: {"strict":true},
    name: "export"
  }, {
    path: "/faq",
    component: _6deeeec6,
    pathToRegexpOptions: {"strict":true},
    name: "faq"
  }, {
    path: "/inloggen",
    component: _4cf43c7a,
    pathToRegexpOptions: {"strict":true},
    name: "inloggen"
  }, {
    path: "/locaties",
    component: _095baf50,
    pathToRegexpOptions: {"strict":true},
    name: "locaties"
  }, {
    path: "/mijn-gegevens",
    component: _0e1f7784,
    pathToRegexpOptions: {"strict":true},
    name: "mijn-gegevens"
  }, {
    path: "/wachtwoord-vergeten",
    component: _00d3176f,
    pathToRegexpOptions: {"strict":true},
    name: "wachtwoord-vergeten"
  }, {
    path: "/wachtwoord-wijzigen",
    component: _3fb18799,
    pathToRegexpOptions: {"strict":true},
    name: "wachtwoord-wijzigen"
  }, {
    path: "/locaties/nieuw",
    component: _3771a7aa,
    pathToRegexpOptions: {"strict":true},
    name: "locaties-nieuw"
  }, {
    path: "/",
    component: _5a6b2899,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/wachtwoord-vergeten/reset/:token",
    component: _0fa5aaad,
    pathToRegexpOptions: {"strict":true},
    name: "wachtwoord-vergeten-reset-token"
  }, {
    path: "/locaties/:id",
    component: _012ca23b,
    pathToRegexpOptions: {"strict":true},
    name: "locaties-id"
  }, {
    path: "/wachtwoord-instellen/:token?",
    component: _4241ba11,
    pathToRegexpOptions: {"strict":true},
    name: "wachtwoord-instellen-token"
  }, {
    path: "/locaties/:id/gegevens",
    component: _8b4833d2,
    pathToRegexpOptions: {"strict":true},
    name: "locaties-id-gegevens"
  }, {
    path: "/locaties/:id/wijzigen",
    component: _b984dce0,
    pathToRegexpOptions: {"strict":true},
    name: "locaties-id-wijzigen"
  }, {
    path: "/*",
    component: _3cb5f91e,
    pathToRegexpOptions: {"strict":true},
    name: "*"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
